<template>
    <v-container fluid>
        <v-row justify='space-between' class="pb-5">
            <v-col>
                <h1>Microsoft Teams</h1>
            </v-col>
            <!-- currentSemester === courseData.semester || nextSemester === courseData.semester || yearlySemester === courseData.semester || courseData.semester ==='466'  -->
            <v-col class="text-right" v-if="false">
                <v-row justify='end' align-end class="mr-1">
                    <!-- <v-space></v-space> -->
                    <div  @click="clickInfoToggle" v-if="currentSemester === courseData.semester || yearlySemester === courseData.semester || courseData.semester ==='466'">
                        <v-icon size="28px" style="margin-top: 1.7rem; margin-right: 1rem;">
                        mdi-help-circle
                        </v-icon>
                    </div>
                    <template>
                        <v-dialog
                            v-model="dialog"
                            scrollable
                            max-width="400px"
                            v-if="currentSemester === courseData.semester || courseData.semester === yearlySemester || courseData.semester ==='466'"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mr-2 mt-5"
                                    color="success"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                SYNC WITH REG
                                </v-btn>

                            </template>
                            <v-card>
                                <v-card-title>Select Section</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 300px;">
                                    <v-row>
                                        <v-checkbox
                                            class="mt-5"
                                            v-model="selectAll"
                                            :label="`เลือกทั้งหมด`"
                                            value="All"
                                            v-if="msTeamsList.length > 0"
                                        ></v-checkbox>
                                    </v-row>
                                    <v-row v-for="(detail, i ) in msTeamsList"
                                            :key="parseInt(i)">
                                            <v-checkbox
                                                class="mt-0 ml-4"
                                                v-model="serviceList"
                                                :label="`${detail.title} (${sectionAfterTitleName(detail.sections)})`"
                                                :value="i"
                                                style=""
                                                v-if="msTeamsList.length > 0"
                                            ></v-checkbox>
                                           
                                    </v-row>
                            
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="dialog = false"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="onHandleAutoSync()"
                                >
                                    Submit
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <div class="mt-5" v-if="courseData.semester !=='466'">
                        <create-teams-modal  :courseData="courseData" @modalSubmit="onModalSubmit" :openModal="openCreateModal" @modalClose="openCreateModal = false"/>
                    </div>
                </v-row>
            </v-col>
        </v-row>
        <v-card>
            <v-card-text>
           
            <v-row>
                <v-col v-if="msTeamsList.length > 0">
                    
                    <div v-for="(msTeams, i) in msTeamsList" :key="i">
                        <v-list-item>
                            <v-list-item-content>
                                <v-tooltip bottom close-delay="2000" color="#333">
                                    <template v-slot:activator="{ on, attrs }" >
                                        <v-list-item-title
                                            v-bind="attrs"
                                            v-on="on">
                                            <v-icon>mdi-circle-small</v-icon>
                                            {{`${msTeams.title} (${sectionAfterTitleName(msTeams.sections)})`}}
                                        </v-list-item-title>
                                        <v-list-item-title v-if="msTeams.jobStatus === 'error'">
                                            <v-icon color="red">mdi-circle-small</v-icon>
                                            <strong class="red--text text--lighten-1">{{` ${hasObjectError(msTeams)}`}}</strong>
                                        </v-list-item-title>
                                    </template>
                                    <span >{{sectionAfterTitleName(msTeams.sections)}}</span>
                                </v-tooltip>
                            </v-list-item-content>

                        
                            <v-list-item-icon>
                                <template  >
                                    <v-row align="center"
                                        justify="center">
                                        <v-col v-if="currentSemester === courseData.semester || courseData.semester === '466' || courseData.semester === yearlySemester">
                                            <v-row>
                                                <div  style="font-size: 10px;">Auto Add/Drop</div>
                                            </v-row>
                                            <v-row style="font-size: 10px; margin-left: 0rem;">
                                                <div class="bi-state-toggle"  >
                                                    
                                                    <button :class="!msTeams.syncEnable? 'bi-state-toggle-button-no active' : 'bi-state-toggle-button-no'" @click="clickSync(msTeams.syncEnable, msTeams.courseid , msTeams._id )"  id="toggle-button3">
                                                    </button>
                                            
                                                    <button :class="msTeams.syncEnable? 'bi-state-toggle-button active' : 'bi-state-toggle-button'" @click="clickSync(msTeams.syncEnable, msTeams.courseid , msTeams._id )" id="toggle-button1">
                                                    </button>
                                                </div>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <v-btn class="mr-1 white--text" width="150px" :color="jobStatusBtn(msTeams.jobStatus)" @click="showDetailcreating(msTeams)">
                                                {{jobStatus(msTeams.jobStatus)}}
                                                <i class="fas fa-spinner fa-pulse  fa-spin fa-fw" v-if="msTeams.jobStatus !== 'created' && msTeams.jobStatus !== 'error' && msTeams.jobStatus !== 'synced' && msTeams.jobStatus !== 'syncing' "></i>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="2" v-if="checkAdmin">
                                            <v-menu
                                                bottom
                                                left
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="clickEdit(msTeams)">
                                                        <v-list-item-title><v-icon>mdi-square-edit-outline</v-icon> Edit</v-list-item-title>
                                                    </v-list-item>
                                                  
                                                    <v-list-item @click="clickDelete(msTeams)">
                                                        <v-list-item-title><v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </template>
                              
                            </v-list-item-icon>
                        </v-list-item>
                        <v-divider v-if="i+1 !== msTeamsList.length"></v-divider>
                    </div>
                </v-col>
                <v-col  class="text-center d-flex flex-column align-center justify-center"  v-else  style="border-style: dashed;border-radius: 5px;cursor: pointer; min-height: 100px" >
                    <!-- <i class="fas fa-plus fa-6x"></i> -->
                    <h2>ไม่มีข้อมูล</h2>
                </v-col>
            </v-row>
            </v-card-text>
        </v-card>
    <course-detail-component />
    <ModalRemoveCourseMsteam :showModal="showNotify" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" :openWeb="openWebNotify" @onClose = "onClose" @onDelete = "onHandleDelete"/>
    <ModalNotify :showModal="showNotifyInfo" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" :openWeb="openWebNotify" @onClose = "onClose"/>
    <ModalNotifyAutoEnroll :showModal="showNotifyEnroll" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" @onConfirm="onConfirm" @onClose = "onClose"/>
    <EditCourse :course-data="serviceDetailCourse" :open-modal="openEdit" :course-all-section="courseData" @onClose = "onClose" @modalUpdate="onModalUpdate"></EditCourse>
    <ModalNotifyInfoCreateFlow :showModal="modalCreate" :openWeb="openWeb" :sectionCompleteShow="createComplete" :sectionUncompleteShow="createUncomplete" @onClose="onClose"></ModalNotifyInfoCreateFlow>

    </v-container>
</template>

<script>
    import CourseDetailComponent from '../components/CourseDetailComponent'
    import CreateTeamsModal from '../components/MsTeams/CreateTeamsModal'
    import apiGatewayCpmService from "@/services/apiGatewayService"
    import ModalNotify from '../components/ModalNotification/ModalNotify.vue'
    import ModalNotifyAutoEnroll from '../components/ModalNotification/ModalNotifyAutoEnroll.vue' 
    import EditCourse from '../components/CourseManagement/EditCourse.vue'
    import ModalRemoveCourseMsteam from '../components/ModalRemoveCourse/ModalRemoveCourseMsteam.vue'
    import ModalNotifyInfoCreateFlow from '../components/ModalNotification/ModalNotifyInfoCreateFlow.vue'
    import Swal from 'sweetalert2'
    const config = require(`@/config`)
    import CryptoJS from 'crypto-js'

    export default {
    name: 'CourseMsTeams',
    components: {
        CourseDetailComponent,
        CreateTeamsModal,
        ModalNotify,
        ModalNotifyAutoEnroll,
        ModalRemoveCourseMsteam,
        EditCourse,
        ModalNotifyInfoCreateFlow
    },
    data() {
        return {
            panel: 0,
            msTeamsList: [],
            openCreateModal: false,
            intervalStatus: null,
            dialog: false,
            serviceList:[],
            sectionSelected:[],
            showNotify : false ,
            showNotifyEnroll : false , 
            textNotifyTitle : "",
            textNotifyDetail : "",
            openWebNotify : "" ,
            CourseSync : {
                status : false , 
                courseId : "" , 
                mappingId : "" 
            },
            openEdit : false ,
            showNotifyInfo : false ,
            serviceDetailCourse : [], 
            dataDelete : [],
            modalCreate : false ,
            createComplete : [],
            createUnComplete : [], 
            openWeb : '',
            checkAdmin : false ,
            emailListAdmin : [
                "athicha.le@cmu.ac.th" , 
                "janejira.kaensarn@cmu.ac.th" , 
                "rapeepan.e@cmu.ac.th" , 
                "chawalid.pun@cmu.ac.th",
                "panithi.panwut@cmu.ac.th" , 
                "cmuonline@cmu.ac.th"
            ],


        }
    },
    watch:{
        serviceList(newVal ){
            this.sectionSelected = [];
            for(let i = 0 ; i < newVal.length ; i++){
                this.sectionSelected.push(this.msTeamsList[newVal[i]]._id);
                // console.log("sectionSelection :" , this.sectionSelected)
            }
            // console.log("sectionList :" , this.serviceList)
        }
    },
    computed: {
        courseData: {
            get () {
                return this.$store.state.sideBarData
            },
            set () {
                this.$store.commit('setSideBarData')
            }
        },
        selectAll:{
            get: function () {
              return false
            },
            
            set: function (value) {
                this.sectionSelected = [];
                this.serviceList = [];
                if (value) {
                    
                    for(let i = 0 ; i < this.msTeamsList.length ; i++){
                        this.sectionSelected.push(this.msTeamsList[i]._id);
                        this.serviceList.push(i)
                    }
                }
            }
        },
        currentSemester() {
            return this.$store.state.currentSemester
        },
        nextSemester() {
            return this.$store.state.nextSemester
        },
        yearlySemester() {
            return this.$store.state.yearlySemester
        }
    },
    created () {
        Swal.fire({
            text: 'loading...',
            allowOutsideClick: false
        })
        Swal.showLoading()
        console.log("this.$route.params.courseId" , this.$route.params.courseId)
        this.getMsTeamsModule(this.$route.params.courseId, true)


        const bytes = CryptoJS.AES.decrypt(localStorage.getItem("profile"), config.aesKey.key)
        this.userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        if(this.emailListAdmin.includes(this.userProfile.email)){
            this.checkAdmin = true
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalStatus)
    },
    methods: {
        hasObjectError(obj){
            if(obj.errorCode.message){
                return obj.errorCode.message.message
            }else{
                return "NOT FOUND"
            }

        },
       
        async getMsTeamsModule (courseId, closeLoading = false) {
            const msTeamsList_ = await apiGatewayCpmService.getCourseMappingList(courseId,"msteams",localStorage.getItem("accessToken"))
            // console.log("ms teams" ,courseId , closeLoading , msTeamsList_)
            if(msTeamsList_.length > 0){

                delete msTeamsList_.status
                clearInterval(this.intervalStatus)
                this.msTeamsList = JSON.parse(JSON.stringify(msTeamsList_));
                // console.log("msteams :>" , this.msTeamsList)

                this.intervalStatus = setInterval(async () => {
                    await this.getMsTeamsModule(this.$route.params.courseId, true)
                }, 5000)
            }
            if(closeLoading) Swal.close()
        },
        onHandleAutoSync(){
            clearInterval(this.intervalStatus)
            var parsedobj = JSON.parse(JSON.stringify(this.sectionSelected))
            console.log("sction Selection ;>" , parsedobj)
            if(parsedobj.length > 0){
                this.dialog = false;
                Swal.fire({
                    icon: "warning",
                    title: "ท่านต้องการเพิ่มลดรายชื่นนักศึกษาให้ตรงกับรายชื่อสำนักทะเบียน" ,
                    text: "",
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if(result.isConfirmed){
                        Swal.fire({
                            text: 'loading...',
                            allowOutsideClick: false
                        })
                        Swal.showLoading()
                        const bytes = CryptoJS.AES.decrypt(localStorage.getItem("profile"), config.aesKey.key)
                        let userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
                        console.log("userProfile" , userProfile)
                        let formData = {
                            "mode":"forceSyncStudents",
                            "mappingsList":parsedobj,
                            "userEmail": userProfile.email
                        }
                        this.UpdateSyncNow(formData);
                       
                    }else{
                        this.sectionSelected = [];
                        this.serviceList = [];
                    }
                    this.intervalStatus = setInterval(async () => {
                        await this.getMsTeamsModule(this.$route.params.courseId, true)
                    }, 5000)
                });
            }else{
                Swal.fire({
                    icon: "warning",
                    title: "ท่านไม่ได้เลือก Section สำหรับการซิงค์" ,
                    text: "",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Cancel'
                });

            }

        },

        showDetailcreating (createStatus) {
            // console.log(`createStatus:${createStatus.jobStatus}`)
            switch (createStatus.jobStatus) {
                case "created":
                    console.log("go to teams")
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break
                case "synced":
                    console.log("go to teams")
                    // this.$router.push(`${createStatus.webUrl}`)
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break
                case "syncing":
                    console.log("go to teams")
                    // this.$router.push(`${createStatus.webUrl}`)
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break
                case "errorCanBeRemove":
                    if(createStatus.webUrl !== "" && createStatus.webUrl !== "undefined"){
                        console.log("go to teams")
                        window.open(`${createStatus.webUrl}`,'_blank')
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'ระบบเกิดข้อพลาด',
                            html: 'เกิดข้อผิดพลาดในการสร้าง microsoft teams <br>กรุณาติดต่อเจ้าหน้าที่',
                            showCloseButton: true,
                            confirmButtonText: '<i class="fas fa-comments"></i> ติดต่อเจ้าหน้าที่',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.open(`https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1`,'_blank')
                            }
                        })
                    }
                    break
                case "errorCanBeRemoveJob":
                    if(createStatus.webUrl !== "" && createStatus.webUrl !== "undefined"){
                        console.log("go to teams")
                        window.open(`${createStatus.webUrl}`,'_blank')
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'ระบบเกิดข้อพลาด',
                            html: 'เกิดข้อผิดพลาดในการสร้าง microsoft teams <br>กรุณาติดต่อเจ้าหน้าที่',
                            showCloseButton: true,
                            confirmButtonText: '<i class="fas fa-comments"></i> ติดต่อเจ้าหน้าที่',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.open(`https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1`,'_blank')
                            }
                        })
                    }
                    break
                case "error":
                    Swal.fire({
                        icon: 'error',
                        title: 'ระบบเกิดข้อพลาด',
                        html: 'เกิดข้อผิดพลาดในการสร้าง microsoft teams <br>กรุณาติดต่อเจ้าหน้าที่',
                        showCloseButton: true,
                        confirmButtonText: '<i class="fas fa-comments"></i> ติดต่อเจ้าหน้าที่',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(`https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1`,'_blank')
                        }
                    })
                    break
                default:
                    Swal.fire({
                        icon: 'info',
                        title: 'กำลังดำเนินการสร้าง',
                        text: 'ระบบอาจใช้เวลาสักครู่ในการประมวลผล'
                    })
            }
        },
        async UpdateSyncNow(formData){
           const response = await apiGatewayCpmService.apiPostJobs(localStorage.getItem("accessToken")  ,formData);
           if(response){
                Swal.fire({
                    icon: 'success',
                    title: 'ระบบกำลังซิงค์นักศึกษา',
                })
                return response;
            }else{
                this.alertError(response.message)
                return false;
            }
        },
        sectionAfterTitleName (sectionArr) {
            for(let i=0;i<sectionArr.length;i++){
                sectionArr[i] = this.filterSection(sectionArr[i])
            }
            return sectionArr
        },
        filterSection (section) {
            let sectionLec = section.substring(0, 3)
            let sectionLab = section.substring(3)
            if (sectionLec === '000' && sectionLab !== '000') {
            return `Lab.${sectionLab}`
            }else if(sectionLec !== '000' && sectionLab === '000'){
            return `Lec.${sectionLec}`
            }
            return section
        },
        onOpenCreatemodal () {
            console.log("onclick")
            this.openCreateModal = true
        },
        async onModalSubmit (val , typeCreate) {
            let reqBody = {
                "module":"msteams",
                "service":"msteams",
                "sections": val
            }
            let createTeamsMapping = {}
            let _createUncomplete = []
            // console.log("reqBody :" , reqBody)
            Swal.fire({
                text: 'loading...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            if(typeCreate === "sumAll" || val.length === 1){
                createTeamsMapping = await apiGatewayCpmService.createCourseMapping(this.$route.params.courseId,reqBody,localStorage.getItem("accessToken"))
                if(createTeamsMapping.status){
                    await this.updateActivate()
                    const createTeams = await this.createTeamsChannel(createTeamsMapping._id)
                    if(createTeams.status){
                        this.createComplete = [val]
                        this.createUncomplete = []
                        this.modalCreate = true
                        Swal.close()
                    }else{
                        this.createComplete = []
                        this.createUncomplete = [{"section" : val , "error" : String(createTeams?.message)}]
                        this.modalCreate = true
                        Swal.close()                    }
                }else{
                    this.createComplete = []
                    this.createUncomplete = [{"section" : val , "error" : String(createTeamsMapping?.message)}]
                    this.modalCreate = true
                    Swal.close()
                }
            }else if(typeCreate === "splitAll"){
                val = val.sort()
                for(let i = 0 ; i < val.length ; i++){
                    reqBody = {
                        "module":"msteams",
                        "service":"msteams",
                        "sections": [val[i]]
                    }
                    createTeamsMapping = await apiGatewayCpmService.createCourseMapping(this.$route.params.courseId,reqBody,localStorage.getItem("accessToken"))
                    if(createTeamsMapping.status){
                        await this.updateActivate()
                        const createTeams = await this.createTeamsChannel(createTeamsMapping._id)
                        if(!createTeams.status){
                            _createUncomplete.push({"section" : val[i] , "error" : String(createTeams?.message)})

                        }
                    }else{
                        _createUncomplete.push({"section" : val[i] , "error" : String(createTeamsMapping?.message)})

                    }
                }
                this.createComplete = []
                this.createUncomplete = _createUncomplete
                console.log("error 3:>" , this.createComplete , this.createUncomplete)
                this.modalCreate = true
                Swal.close()
              }
            
        },
        async updateActivate () {
            await this.getMsTeamsModule(this.$route.params.courseId)
            const reqBody = {
                "activated": true
            }
            for(let i=0;i<this.msTeamsList.length;i++){
                if(!this.msTeamsList[i].activated){
                    let resUpdateActivate = await apiGatewayCpmService.updateMapping(this.msTeamsList[i].courseid, this.msTeamsList[i]._id, reqBody, localStorage.getItem("accessToken"))
                    if(resUpdateActivate.status){
                        console.log(resUpdateActivate)
                    }
                }
            }
            this.getMsTeamsModule(this.$route.params.courseId)
        },
        async createTeamsChannel (mappingId) {
            const reqBody = {
                "mode": "createChannelByMapping",
                "mappingId": mappingId,
                "tagName": `add-student-to-teams-${mappingId}`
            }
            const resCreateChannel = await apiGatewayCpmService.createJobs(reqBody, localStorage.getItem("accessToken"))
            return resCreateChannel
        },
       
        clickSync (status , courseId , mappingId) {
            // console.log("clickSync" , status , courseId , mappingId)
            this.CourseSync.status = status ;
            this.CourseSync.courseId = courseId ;  
            this.CourseSync.mappingId = mappingId ; 

            this.showNotifyEnroll = true ;
            this.textNotifyTitle = status  ? "ปิดการนำเข้ารายชื่ออัตโนมัติ" : "เปิดการนำเข้ารายชื่ออัตโนมัติ";
            this.textNotifyDetail = `บริการนี้จะทำการเพิ่มรายชื่อนักศึกษาเข้าเท่านั้น หากต้องการให้รายชื่อนักศึกษาตรงกับสำนักทะเบียน กรุณากดปุ่ม "Sync with reg"`;
        },
        alertError (errorMessage) {
            let text = ""
            switch (errorMessage) {
                case "mappingHasReadyExit":
                    text = "course already exist"
                    break
                default:
                    text = "error"
                    break
            }
            Swal.fire({
                icon: 'error',
                title: 'Create fail',
                text: `${text}`,
            })
        },
        getStatus (msTeamsList) {
            this.getJobStatus(msTeamsList)
        },
        async getJobStatus (msTeamsList) {
            let queryData = ""
            for(let i=0;i<msTeamsList.length;i++){
                queryData = `courseId=${msTeamsList[i].courseid}&mappingId=${msTeamsList[i]._id}`
                let resStatus = await apiGatewayCpmService.getJobs(queryData, localStorage.getItem("accessToken"))
                if(resStatus.length > 0){
                    if(resStatus[0].status === "teamClassCreated" && msTeamsList[i].webUrl === ""){
                        this.getMsTeamsModule(this.$route.params.courseId)
                        break
                    }
                    msTeamsList[i].job = resStatus[0]
                }else{
                    msTeamsList[i].job = {
                        "status": "not found"
                    }
                }
            }
            this.msTeamsList = msTeamsList
        },
        jobStatus (status) {
            switch (status) {
                case "created":
                    return "Go to teams"
                case "synced":
                    return "Go to teams"
                case "syncing":
                    return "Go to teams"
                case "error":
                    // if(status.webUrl !== "" && status.webUrl !== "undefined"){
                    //     return "Go to teams"
                    // }
                    return "Error"
                case "errorCanBeRemoveJob":
                    // if(status.webUrl !== "" && status.webUrl !== "undefined"){
                    //     return "Go to teams"
                    // }
                    return "Error"

                default:
                    return "Creating"
            }
        },
        jobStatusBtn (status) {
            switch (status) {
                case "teamClassCreated":
                    return "#745CA8"
                case "errorCanBeRemove":
                    return "red"
                case "errorCanBeRemoveJob":
                    // if(status.webUrl !== "" && status.webUrl !== "undefined"){
                    //     return "#745CA8"
                    // }
                    return "red"
                case "error":
                    return "red"
                default:
                    return "#745CA8"
            }
        },
        clickDelete (data) {
            this.showNotify = true ;
            this.textNotifyTitle = `ยืนยันการลบ \n "Microsoft Teams"  กลุ่มนี้ `;
            this.textNotifyDetail = "รายชื่ออาจารย์เเละนักศึกษาทั้งหมด \n จะถูกลบออกจากกลุ่ม ต้องการลบหรือไม่";
            this.dataDelete = data;
        },
        onClose(){
            this.openEdit = false ;
            this.showNotify = false , 
            this.showNotifyInfo = false;
            this.showNotifyEnroll = false ; 
            this.textNotifyTitle = "",
            this.textNotifyDetail = "",
            this.openWebNotify = "", 
            this.modalCreate = false
        },

        async onConfirm (){
            console.log("onConfirm")
            let formData = {
                    "syncEnable" : true
            };
            if(this.CourseSync.status){
                formData ={
                    "syncEnable" : false
                }
            }

            let tmp = apiGatewayCpmService.putCourseMappingSync(this.CourseSync.courseId,this.CourseSync.mappingId,localStorage.getItem("accessToken") , formData);
            if(tmp){
                Swal.fire({
                    text: 'loading...',
                    allowOutsideClick: false
                })
                Swal.showLoading()
                this.getMsTeamsModule(this.$route.params.courseId, true)
                this.onClose()
            }
        },
        clickEdit (msTeam) {
            this.openEdit = true
            this.serviceDetailCourse = msTeam
        },
        async onModalUpdate( val ){
            console.log("connect api update" , val)

        },
        async onHandleDelete(){
            Swal.fire({
                    text: 'loading...',
                    allowOutsideClick: false
                })
            Swal.showLoading()
            // console.log("del:>", this.dataDelete._id , this.dataDelete.courseid)
            const res = await apiGatewayCpmService.deleteMappingCourse( localStorage.getItem("accessToken") , this.dataDelete.courseid , this.dataDelete._id)
            if(res.status){
                Swal.fire({
                    icon: 'success',
                    title: 'ทำการลบสำเร็จ',
                })
                this.onClose()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'ทำการลบไม่สำเร็จ',
                })
                this.onClose()
            }
        },
        clickInfoToggle(){
            console.log("info")
            this.showNotifyInfo = true ; 
            this.textNotifyTitle = "Sync with REG";
            this.textNotifyDetail = "คือ การทำให้รายชื่อนักศึกษาตรงกับที่มีในสำนักทะเบียน \n สามารถทำการได้ตั้งแต่เวลา 8.00-22.00 น." ;
            this.openWebNotify = "https://www.info.tlic.cmu.ac.th/contact-us" ;
            
        },
    },
    }
</script>
